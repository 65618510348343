<template>
  <b-card>
    <template #title>
      <feather-icon
        icon="UserIcon"
        size="16"
        class="mr-0 mr-sm-50"
      />
      <span class="d-none d-sm-inline">Account</span>
    </template>
    <edit-account-tab
      :user-data="userData"
      class="mt-2 pt-75"
    />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import EditAccountTab from './EditAccount.vue'

export default {
  components: {
    BCard,
    EditAccountTab,
  },
  data() {
    return {
      userData: this.$profile().data.user,
    }
  },
}
</script>

<style>

</style>
