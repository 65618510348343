export default function getRolesData() {
  return [
    {
      module: 'Admin',
      description: 'Admin role has access to everything related to the company.',
      enabled: false,
      checkAccess: false,
    },
    {
      module: 'User',
      description: 'User has limited access to the company, able to only view / create / edit shipments.',
      enabled: false,
      checkAccess: false,
    },
    {
      module: 'Viewer',
      description: 'Viewer has limited access to the company, able to only view shipments.',
      enabled: false,
      checkAccess: false,
    },
    {
      module: 'Declarant',
      description: 'Declarant has permissions to submit and manage customs declarations.',
      enabled: false,
      checkAccess: true,
    },
  ]
}
